<template>
  <div id="test_Index5">
    <div>
      <el-row type="flex" justify="end">
        <el-col :span="5">
          <el-button size="small" icon="el-icon-document-add" @click="$router.push({path: '/mailbox/edit'})">添加
          </el-button>
        </el-col>
      </el-row>
    </div>
    <div class="block">
      <el-row type="flex" justify="center">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-timeline>
            <el-timeline-item :timestamp="String(item.tpCreationTime)" placement="top"
                              v-for="(item, index) in dataList"
                              :key="index">
              <el-card>
                <el-row type="flex" justify="end">
                  <el-col :span="3"><i class="el-icon-position" style="cursor:pointer;" @click="sent(item)"></i>
                  </el-col>
                  <el-col :span="3"><i class="el-icon-document-delete" style="cursor:pointer;" @click="del(item)"></i>
                  </el-col>
                  <el-col :span="3"><i class="el-icon-edit-outline" style="cursor:pointer;" @click="edit(item)"></i>
                  </el-col>
                </el-row>
                <div @click="showDialogVisible(item)">
                  <h4>TITLE: {{ item.tpTitle }}</h4>
                  <div style="height: 50px" class="ql-editor" v-html="item.tpContent"/>
                  <!--            <p>{{item.tpContent}}</p>-->
                </div>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </el-col>
      </el-row>
    </div>

    <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        width="90%">
      <p><span style="font-weight: bold">发送至:</span> {{ dialogItem.tpToEmail }}</p>
      <div class="ql-editor" v-html="dialogHtml"/>
    </el-dialog>
  </div>
</template>

<script>
import {parseTime} from '@/utils';
import ask from "@/request/expand/mailbox/index";

export default {
  name: "mailbox_list",
  data() {
    return {
      askIndex: new ask(),
      dataList: [{
        tpTitle: '暂无数据',
        tpContent: '右上角添加一条时光邮件',
        tpCreationTime: new Date(),
        tpIsSent: true
      }],
      dialogVisible: false,
      dialogTitle: '',
      dialogHtml: '',
      dialogItem: {},
    }
  },
  methods: {
    parseToTime(time) {
      return parseTime(time)
    },

    /**
     * 编辑
     * @param tpId
     */
    edit(item) {
      if (!item.tpIsSent) {
        this.$router.push({
          name: 'mailbox_edit',
          params: {
            item: item
          }
        })
      } else {
        this.$notify({
          title: '警告',
          message: '邮件已发送, 暂不能修改.',
          type: 'warning'
        });
      }
    },
    showDialogVisible(item) {
      this.dialogVisible = true
      this.dialogTitle = item.tpTitle
      this.dialogHtml = item.tpContent
      this.dialogItem = item
    },
    sent(item) {
      if (item.tpIsSent) {
        this.$notify({
          title: '警告',
          message: '邮件已发送, 不能重复发送.',
          type: 'warning'
        });
      } else {
        this.askIndex.sentEmail(item.tpId).then(res => {
          if (res.code === 'success') {
            this.$notify({
              title: '成功',
              message: '发送成功',
              type: 'success'
            });
          }
        })
      }
    },
    del(item) {
      this.$confirm('是否确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'msgBox'
      }).then(() => {
        this.askIndex.delTimePostData(item.tpId).then(res => {
          if (res.code === 'success') {
            const index = this.dataList.indexOf(item)
            if (index !== -1) {
              this.dataList.splice(index, 1)
            }
            this.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success'
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  },
  created() {
    this.askIndex.getTimePostData().then(res => {
      if (res.code === 'success' && res.msg.length > 0) {
        this.dataList = res.msg
      }
    })
  }
}
</script>

<style scoped>
#test_Index5 {
  margin-top: 10px;
}

.ql-editor {
  box-sizing: border-box;
  line-height: 1.42;
  height: 100%;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  -o-tab-size: 4;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}

ul {
  padding-left: 0px;
}

.block {
  margin-top: 10px;
}
</style>
